import { media, color, Label } from "@sencrop/ui-components";
import { WebTitle, webtext, WebText } from "@sencrop/ui-web";
import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import LinkElement from "../containers/LinkElement";
import LinkItems from "../components/LinkItems";
import BackgroundElement from "../components/BackgroundElement";
import { blockCss, containerCss, contentCss } from "../helpers/style";
import LinkRenderer, { Link } from "../containers/LinkRenderer";

const SectionLinks = ({
  backgroundColor,
  subhead,
  title,
  description,
  linkItems,
  design,
  link,
  textAfterLinkItems,
  backgroundDesktop,
  addWhiteBannerBelowBackground,
  subLinkTitle,
  subLinkItems,
  tagMenu,
}: GatsbyTypes.SectionLinksFragmentFragment) => {
  return (
    <BackgroundElement
      style={{
        backgroundSize: `auto ${
          addWhiteBannerBelowBackground ? "calc(100% - 140px)" : "100%"
        }`,
      }}
      backgroundImage={backgroundDesktop?.gatsbyImageData}
      backgroundPosition="center top"
      desktopOnly
      color={backgroundColor as "grey" | "white" | "blue" | undefined}
      overflow={tagMenu ? "visible" : undefined}
    >
      <WhiteBanner $banner={addWhiteBannerBelowBackground}>
        <Wrapper>
          <Header>
            <TitleContent>
              {subhead && (
                <SubHead>
                  <WebTitle
                    sizes={["xxl", "xxxxl", "xxxxl", "xxxxl"]}
                    color="branding-secondary-light"
                    aria-hidden={true}
                  >
                    {subhead}
                  </WebTitle>
                </SubHead>
              )}
              <WebTitle as="h2" size="xl" color="primary">
                {title}
              </WebTitle>
            </TitleContent>
            {description?.childMarkdownRemark?.html && (
              <TextContent
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
                style={{ whiteSpace: "pre-wrap" }}
              />
            )}
          </Header>
          {linkItems && (
            // @ts-ignore: linkItems is readonly 🤷‍♂️
            <LinkItems
              design={design as string}
              links={linkItems}
              tagMenu={tagMenu}
            />
          )}
          {textAfterLinkItems?.childMarkdownRemark?.html && (
            <TextBeforeLink
              dangerouslySetInnerHTML={{
                __html: textAfterLinkItems.childMarkdownRemark.html,
              }}
              style={{ whiteSpace: "pre-wrap" }}
            />
          )}
          <LinkSection>
            {link && (
              <LinkElement
                centered
                link={link as Link | undefined}
                color="green"
              >
                {link.label}
              </LinkElement>
            )}
          </LinkSection>
          {subLinkItems && (
            <LinkSection>
              {subLinkTitle && (
                <WebTitle
                  color="primary"
                  size="l"
                  style={{ marginBottom: "1rem" }}
                >
                  {subLinkTitle}
                </WebTitle>
              )}
              <SubLinkItemsWrapper>
                {subLinkItems.map((subLink) => (
                  <LinkRenderer link={subLink}>
                    <Label variant="soft" style={{ cursor: "pointer" }}>
                      {subLink?.label}
                    </Label>
                  </LinkRenderer>
                ))}
              </SubLinkItemsWrapper>
            </LinkSection>
          )}
        </Wrapper>
      </WhiteBanner>
    </BackgroundElement>
  );
};

export default SectionLinks;

export const fragment = graphql`
  fragment SectionLinksFragment on ContentfulSectionLinks {
    backgroundDesktop {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
    backgroundColor
    title
    subhead
    link {
      ...LinkFragment
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    linkItems {
      link {
        ...LinkFragment
      }
      button {
        ...LinkFragment
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      title
      subtitle
      image {
        gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        title
      }
      backgroundImage {
        gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      }
      tags
      isNew
    }
    textAfterLinkItems {
      childMarkdownRemark {
        html
      }
    }
    subLinkTitle
    subLinkItems {
      ...LinkFragment
    }
    design
    addWhiteBannerBelowBackground
    tagMenu
  }
`;

type WhiteBannerProps = {
  $banner?: boolean;
};

const WhiteBanner = styled.div<WhiteBannerProps>`
  ${(props) =>
    props.$banner &&
    css`
      ${media.greaterThan("tablet")`
        position: relative;
        :after {
          position: absolute;
          content: "";
          z-index: -1;
          background-color: ${color("background-primary:10")};
          height: 140px;
          width: 100vw;
          bottom: 0;
        }
      `}
    `}
`;

const Wrapper = styled.div`
  ${containerCss}
  ${contentCss}
  ${blockCss}
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  ${media.greaterThan("tablet")`
    padding-top: 2.5rem;
    padding-bottom: 3.5rem;
    }
  `}
`;

const Header = styled.div`
  max-width: 796px;
  margin: 0 auto;
`;

const TextBeforeLink = styled.div`
  max-width: 796px;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  ${media.greaterThan("tablet")`
    margin-bottom: 1.5rem;
  `}
  p {
    color: ${color("text-primary:75")};
    ${webtext("text", "l")};
  }
`;

const TitleContent = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
  ${media.greaterThan("tablet")`
    margin-bottom: 1rem;
  `}
`;

const SubHead = styled.div`
  word-wrap: break-word;
  margin: auto;
  position: relative;
  margin-top: -2.5rem;
  bottom: -1.5rem;
  ${media.greaterThan("tablet")`
    margin-top: -3.35rem;
    bottom: -2.35rem;
  `}
`;

const TextContent = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
  ${media.greaterThan("tablet")`
    margin-bottom: 2rem;
  `}
  p {
    color: ${color("text-primary:75")};
    ${webtext("text", "l")};
  }
`;

const LinkSection = styled.div`
  margin-top: 1.625rem;
`;

const SubLinkItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
