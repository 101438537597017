import {
  Button,
  ChipButton,
  color,
  Label,
  media,
  useBreakpoint,
  useI18n,
} from "@sencrop/ui-components";
import { webtext, WebTitle } from "@sencrop/ui-web";
import { getSrc, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import LinkElement from "../containers/LinkElement";
import LinkRenderer, { Link } from "../containers/LinkRenderer";
import BackgroundImageProduct from "../images/links/links_background.png";
import ImageElement from "./ImageElement";
import { ChipButtonProps } from "@sencrop/ui-components/dist/components/Chip/Button/ChipButton";

const PRODUCT_TYPE = "product";

type LinkItemsProps = {
  design?: string;
  tagMenu?: boolean;
  backgroundColor?: string;
  links?: {
    title?: string;
    subtitle?: string;
    description?: {
      childMarkdownRemark?: {
        html?: string;
      };
    };
    image?: {
      title?: string;
      gatsbyImageData?: IGatsbyImageData;
    };
    backgroundImage?: IGatsbyImageData;
    link?: Link & {
      label?: string;
    };
    button?: Link & {
      label?: string;
    };
    tags?: string[];
    isNew?: boolean;
  }[];
};
type filter = "all" | "gel" | "meteo" | "traitement" | "irrigation";

const LinkItems = ({
  design,
  tagMenu,
  backgroundColor,
  links = [],
}: LinkItemsProps) => {
  const isProductType = design === PRODUCT_TYPE;
  const linkImages = links
    .map((link) => link.image)
    .filter((image) => image != null);
  const ghostImage = linkImages[0];

  const { t } = useI18n();
  const onMobileOrTablet = useBreakpoint("mobile", "tablet");

  const [linkFilter, setLinkFilter] = useState("all");
  const filterList: filter[] = [
    "all",
    "gel",
    "meteo",
    "traitement",
    "irrigation",
  ];
  const filterIconsList: Record<filter, ChipButtonProps["startIcon"]> = {
    all: "sencrop-o",
    meteo: "forecast-o",
    gel: "frost",
    traitement: "plant-disease-o",
    irrigation: "hygro-o-1",
  };

  return (
    <>
      {tagMenu ? (
        <ChipContainer $backgroundColor={backgroundColor}>
          {filterList.map((filter) => {
            return (
              <ChipButton
                onClick={() => setLinkFilter(filter)}
                isSelected={filter === linkFilter}
                startIcon={filterIconsList[filter]}
                selectedBackgroundColor="global-green"
              >
                {`${t(`section_links.chip.${filter}`)}${
                  onMobileOrTablet ? "" : " "
                }(${
                  filter === "all"
                    ? links.length
                    : links.filter((linkGiven) => {
                        return linkGiven.tags?.includes(filter);
                      })?.length
                })`}
              </ChipButton>
            );
          })}
        </ChipContainer>
      ) : null}
      <LinksContainer $count={links.length} $design={design}>
        {links
          .filter((link) => {
            return (
              !isProductType ||
              linkFilter === "all" ||
              link.tags?.includes(linkFilter)
            );
          })
          .map((link, index) => {
            return (
              <LinkItem key={index}>
                {link.isNew ? (
                  <Label
                    variant="danger"
                    style={{
                      position: "absolute",
                      top: "100px",
                      right: "20px",
                      padding: "5px 15px",
                    }}
                  >
                    New
                  </Label>
                ) : null}
                {isProductType ? (
                  <LinkProductContainer
                    link={link}
                    ghostImage={link.image ? undefined : ghostImage}
                  />
                ) : (
                  <LinkContent>
                    <ImageContainer>
                      {link.image && (
                        <ImageElement
                          gatsbyImageData={link.image.gatsbyImageData}
                          style={{ height: "100%" }}
                          alt={link.image.title || ""}
                        />
                      )}
                    </ImageContainer>
                    <LinkInfos>
                      <div>
                        <WebTitle
                          as="h3"
                          size="l"
                          color="primary"
                          style={{ marginBottom: "8px", fontWeight: "800" }}
                        >
                          {link.title}
                        </WebTitle>
                        {link.description?.childMarkdownRemark?.html && (
                          <LinkDescription
                            dangerouslySetInnerHTML={{
                              __html: link.description.childMarkdownRemark.html,
                            }}
                            style={{ whiteSpace: "pre-wrap" }}
                          />
                        )}
                      </div>
                      {link.link && (
                        <LinkElementContainer>
                          <LinkElement link={link.link} color="green">
                            {link.link.label}
                          </LinkElement>
                        </LinkElementContainer>
                      )}
                    </LinkInfos>
                  </LinkContent>
                )}
              </LinkItem>
            );
          })}
      </LinksContainer>
    </>
  );
};

type LinkProductContainerProps = {
  link?: {
    title?: string;
    subtitle?: string;
    description?: {
      childMarkdownRemark?: {
        html?: string;
      };
    };
    image?: {
      title?: string;
      gatsbyImageData?: IGatsbyImageData;
    };
    backgroundImage?: IGatsbyImageData;
    link?: Link & {
      label?: string;
    };
    button?: Link & {
      label?: string;
    };
    tags?: string[];
    isNew?: boolean;
  };
  ghostImage?: {
    title?: string;
    gatsbyImageData?: IGatsbyImageData;
  };
};

const LinkProductContainer = ({
  link,
  ghostImage,
}: LinkProductContainerProps) => {
  if (!link) {
    return null;
  }

  const backgroundSrc = link.backgroundImage
    ? getSrc(link.backgroundImage)
    : undefined;

  return (
    <>
      <ImageProductContainer>
        {link.image && (
          <ImageElement
            gatsbyImageData={link.image.gatsbyImageData}
            style={{
              height: "100%",
            }}
            alt={link.image.title || ""}
          />
        )}
      </ImageProductContainer>
      <GhostImageProductContainer>
        {!link.image && ghostImage && (
          <ImageElement
            gatsbyImageData={ghostImage.gatsbyImageData}
            style={{
              height: "100%",
              visibility: "hidden",
            }}
            alt={ghostImage.title || ""}
          />
        )}
      </GhostImageProductContainer>
      <LinkProductContent
        backgroundSrc={backgroundSrc}
        $noImage={!link.image}
        $noImageAtAll={!link.image && !ghostImage}
      >
        <div>
          <WebTitle
            as="h3"
            size="l"
            color="branding-secondary"
            style={{ marginBottom: "12px", fontWeight: "800" }}
          >
            {link.title}
          </WebTitle>
          {link.subtitle && (
            <WebTitle
              as="h3"
              size="m"
              style={{ marginBottom: "8px", fontWeight: "600" }}
            >
              {link.subtitle}
            </WebTitle>
          )}
          {link.description?.childMarkdownRemark?.html && (
            <LinkDescription
              dangerouslySetInnerHTML={{
                __html: link.description.childMarkdownRemark.html,
              }}
              style={{ whiteSpace: "pre-wrap" }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "baseline",
            gap: "12px",
          }}
        >
          {link.link && (
            <LinkElementContainer>
              <LinkElement link={link.link} color="black">
                {link.link.label}
              </LinkElement>
            </LinkElementContainer>
          )}
          {link.button && (
            <LinkRenderer link={link.button}>
              <Button
                style={{
                  background: `linear-gradient(
        236deg,
        #436cca 10.65%,
        #203a9a 100%,
        #263994 100%
        )`,
                }}
              >
                {link.button.label}
              </Button>
            </LinkRenderer>
          )}
        </div>
      </LinkProductContent>
    </>
  );
};

export default LinkItems;

type LinksContainerProps = { $count: number; $design?: string };

const LinksContainer = styled.div<LinksContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
  ${(props) => css`
    ${media.greaterThan("tablet")`
      grid-gap: 1.5rem;
      grid-template-columns: ${
        props.$count > 3 && props.$design != "product"
          ? "1fr 1fr"
          : props.$design != "product"
          ? `repeat(${props.$count},1fr)`
          : props.$count < 5
          ? `repeat(${props.$count},1fr)`
          : "1fr 1fr 1fr"
      };
    `}
  `};
`;

const LinkItem = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  border-radius: 6px;
  margin-bottom: 1rem;
  width: 96px;
  img {
    object-fit: contain !important;
    object-position: 0 0 !important;
  }
  ${media.greaterThan("tablet")`
    margin-bottom: 0;
  `}
`;

type ChipContainerProps = { $backgroundColor?: string };

const ChipContainer = styled.div<ChipContainerProps>`
  display: flex;
  gap: 10px;
  padding: 15px 2px;
  justify-content: center;
  background-color: ${(props) => {
    return props.$backgroundColor ? props.$backgroundColor : "#f7f9fa";
  }};
  ${media.lessThan("tablet")`
  position: sticky;
  top: 50px;
  z-index: 1;
  overflow: scroll;
  justify-content: left;
  `}
`;

const LinkContent = styled.div`
  border-radius: 6px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem 1.5rem;
  background-color: ${color("background-primary:10")};
  ${media.greaterThan("tablet")`
    display: grid;
    grid-template-columns: 96px auto;
    grid-gap: 2.5rem;
    height: auto;
  `}
`;

const LinkInfos = styled.div`
  ${media.greaterThan("tablet")`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

const ImageProductContainer = styled.div`
  position: relative;
  margin: 0 0 1.25rem 0;
  width: 180px;
`;

const GhostImageProductContainer = styled.div`
  position: relative;
  display: none;
  margin: 0 0 1.25rem 0;
  ${media.greaterThan("tablet")`
    display: block;
  `}
`;

type LinkProductContentProps = {
  $noImage?: boolean;
  backgroundSrc?: string;
  $noImageAtAll?: boolean;
};

const LinkProductContent = styled.div<LinkProductContentProps>`
  margin: ${(props) => (props.$noImage ? "0" : "-150px 0 0 0")};
  padding: ${(props) =>
    props.$noImage ? "2rem 1.5rem" : "130px 1.5rem 2rem 1.5rem"};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  background-size: 260px;
  background-color: ${color("branding-secondary:10")};
  background-image: ${(props) =>
    props.backgroundSrc
      ? `url(${props.backgroundSrc})`
      : `url(${BackgroundImageProduct as string})`};
  background-repeat: no-repeat;
  background-position: top right;
  ${(props) => css`
    ${media.greaterThan("tablet")`
      margin: ${props.$noImageAtAll ? "0" : "-150px 0 0 0"};
      padding: ${
        props.$noImageAtAll ? "2rem 1.5rem" : "130px 1.5rem 2rem 1.5rem"
      };
      flex-grow: 1;
    `}
  `};
`;

const LinkDescription = styled.div`
  margin-bottom: 1rem;
  p {
    color: ${color("text-secondary")};
    ${webtext("text", "l")};
  }
`;
const LinkElementContainer = styled.div`
  display: flex;
  width: auto;
`;
